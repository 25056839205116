import { useCallback, useMemo } from 'react';
import { vehicleIneligible, defaultIneligibleParams } from '../../utils/offerEvents';
import { useOfferContext } from '../../context/offerContext';
import { useVehicleInfo } from '../../context/vehicleInfo';
import { IContinuation } from '../../types/IOfferResponse';

interface IVehicleIneligibleParams {
    isSecondIneligible?: boolean;
    mileage?: number;
    continuation?: IContinuation;
    reason?: string;
    pageType?: string;
    isPicsyEligible?: boolean;
}

interface IUseVehicleIneligible {
    onIneligible?: () => void;
}

export default function useTriggerIneligibleEvent({ onIneligible }: IUseVehicleIneligible = {}) {
    const { quoteId, saveResult, setIneligible, handleIneligible } = useOfferContext();
    const { vehicleInfo } = useVehicleInfo();

    const trigger = useCallback(
        (params: IVehicleIneligibleParams) => {
            vehicleIneligible({
                ...defaultIneligibleParams,
                saveResult,
                quoteId,
                vehicleInfo,
                ...params,
            });

            if (handleIneligible) {
                setIneligible(true);
            }

            if (typeof onIneligible === 'function') {
                onIneligible();
            }
        },
        [saveResult, quoteId, vehicleInfo, setIneligible, onIneligible, handleIneligible]
    );

    return useMemo(() => ({ trigger }), [trigger]);
}
