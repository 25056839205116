import * as React from 'react';
import { Button as KmxButton } from '@kmx/legos-react-button';
import classNames from 'classnames';
import AppraisalPhoto from '../images/appraisal_photo.svg';
import { ineligibleScheduleAppointment, picsyStart } from '../utils/redirect';
import * as styles from './SimplifiedIneligiblePhotoCapture.module.scss';
import { useOfferContext } from '../context/offerContext';
import SwipableContainer from './SwipableContainer';
import { withAnalyticsClickTracking } from '../utils/analytics';
const Button = withAnalyticsClickTracking(KmxButton);

const scenario = 'ineligible';
const PICSY_ADCODE = 'IO_OFFERPAGE';
const SLIDES = [
    {
        header: 'Grab your keys and choose a safe location',
        content: `Bring your keys in order to start your car and pop the hood.\n\nChoose a well-lit, safe area where you can walk around the car (about 5 feet away) without putting yourself in danger.`,
    },
    {
        header: 'Disconnect from in-home Wi-Fi',
        content: "When you go to your car, make sure you aren't still connected to your in-home Wi-Fi.",
    },
    {
        header: 'Get your phone',
        content: "You'll need a phone or tablet to take pictures. Scan the QR code to get started.",
    },
];

const SimplifiedIneligiblePhotoCapture: React.FC = () => {
    const { quoteId } = useOfferContext();

    return (
        <div className={styles.simplifiedPhotoCaptureIneligible}>
            <div className={styles.photoCaptureWrapper}>
                <AppraisalPhoto />
                <h4 className="kmx-typography--display-3">We need more information to give you an offer</h4>
                <p className="kmx-typography--body-1">
                    Our online offers are based on recent appraisals of similar cars. and we have not seen many like
                    yours lately. You can take some photos of your car and get an offer within the next business day.
                </p>
                <Button
                    id="ineligible-continue-photo-capture"
                    level="primary"
                    onClick={() => picsyStart(quoteId, scenario, PICSY_ADCODE)}
                >
                    Get started
                </Button>
            </div>
            <div className={styles.needToKnowWrapper}>
                <h5 className="kmx-typography--display-3">What you need to know</h5>
                <SwipableContainer childsWidth={288} childsHeight={230}>
                    {SLIDES.map((card, index) => (
                        <div key={index} className={classNames([styles.card])}>
                            <h6 className="kmx-typography--display-3">{card.header}</h6>
                            <p className="kmx-typography--body-1">{card.content}</p>
                        </div>
                    ))}
                </SwipableContainer>
            </div>
            <div className={styles.scheduleAppointmentWrapper}>
                <p className="kmx-typography--body-1">
                    Rather do this in person? Bring your car to your nearest CarMax and we will take a look.
                </p>
                <Button
                    id="ineligible-continue-schedule-appt"
                    level="tertiary"
                    onClick={() => ineligibleScheduleAppointment()}
                >
                    Set my appointment
                </Button>
            </div>
        </div>
    );
};

export default SimplifiedIneligiblePhotoCapture;
