import * as React from 'react';
import { useState, useEffect } from 'react';
import { Button as KmxButton } from '@kmx/legos-react-button';
import classNames from 'classnames';
import TakePhoto from '../images/take_photo.svg';
import { ineligibleScheduleAppointment, picsyStart } from '../utils/redirect';
import * as styles from './IneligiblePhotoCapture.module.scss';
import Card from './Card';
import { useOfferContext } from '../context/offerContext';
import { withAnalyticsClickTracking } from '../utils/analytics';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useVehicleInfo } from '../context/vehicleInfo';
const Button = withAnalyticsClickTracking(KmxButton);

const PHOTO_CAPTURE_SELECTION = 1;
const SCHEDULE_APPT_SELECTION = 2;
const PICSY_ADCODE = 'IO_OFFERPAGE';

const IneligiblePhotoCapture: React.FC = () => {
    const appInsights = useAppInsightsContext();
    const [selection, setSelection] = useState(null);
    const { quoteId, offer } = useOfferContext();
    const { vehicleInfo } = useVehicleInfo();

    const scenario = 'ineligible';

    useEffect(() => {
        appInsights.trackEvent({
            name: 'Ineligible (photo capture) page - viewed',
            properties: {
                quoteId: quoteId || undefined,
                timestamp: new Date().toISOString(),
                vin: vehicleInfo.vin || undefined,
            },
        });
    }, []);

    const onContinue = () =>
        selection === PHOTO_CAPTURE_SELECTION
            ? picsyStart(quoteId, scenario, PICSY_ADCODE)
            : ineligibleScheduleAppointment();

    //ugly formatting in this function to make the linter happy =/
    const continueButtonId = () => {
        switch (selection) {
            case PHOTO_CAPTURE_SELECTION:
                return 'ineligible-continue-photo-capture';
            case SCHEDULE_APPT_SELECTION:
                return 'ineligible-continue-schedule-appt';
            default:
                return 'ineligible-continue-no-selection';
        }
    };

    return (
        <div id="photo-capture-ineligible" className={styles.icoIneligible}>
            <TakePhoto className={styles.takePhotoImage} />
            <h4 className="kmx-typography--display-3">We&apos;d like to see your vehicle</h4>
            <p className="kmx-typography--body-1">
                Our online offers are based on recent appraisals of similar cars, and we have not seen many like yours
                lately.{' '}
                {offer?.isPicsyEligible &&
                    `You can take some photos of your car and get an offer 
                within the next business day or just bring your car to your nearest CarMax and we will take a look.`}
            </p>
            <h5 className={classNames(['kmx-typography--display-2', styles.ctaTextPrompt])}>
                Ready to get your offer?
            </h5>
            <div className={styles.cardContainer}>
                {offer?.isPicsyEligible && (
                    <Card
                        className={styles.card}
                        header="Take some photos of your car"
                        content="Take about 5 minutes and send us some pictures of your car"
                        selected={selection === PHOTO_CAPTURE_SELECTION}
                        onClick={() => setSelection(PHOTO_CAPTURE_SELECTION)}
                    />
                )}
                <Card
                    className={styles.card}
                    header="Schedule an in-store appraisal"
                    content="Schedule a time to come into your nearest CarMax and we will take a look."
                    selected={selection === SCHEDULE_APPT_SELECTION}
                    onClick={() => setSelection(SCHEDULE_APPT_SELECTION)}
                />
                <div id={continueButtonId()}>
                    <Button
                        className={styles.continueButton}
                        id={continueButtonId()}
                        level="primary"
                        onClick={() => onContinue()}
                        disabled={!selection}
                    >
                        Continue
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default IneligiblePhotoCapture;
