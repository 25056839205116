import * as React from 'react';
import { useMemo } from 'react';
import classNames from 'classnames';
import AlertIcon from '../../../assets/AlertIcon.svg';
import * as styles from '../ConditionsBody/ConditionsBody.module.scss';
import ConditionalRender from '../../ConditionalRender';
import { IDynamicConditionQuestion } from '../../../types/IConditionQuestion';
import { useVehicleInfo } from '../../../context/vehicleInfo';
import { useStoreInfo } from '../../../context/storeInfo';

interface IAfterMarketModificationAlert {
    question: IDynamicConditionQuestion;
}

//question ID consts for questions with special logic
const AfterMarketModificationsQuestionId = 700;

const AfterMarketModificationAlert: React.FC<IAfterMarketModificationAlert> = ({ question }) => {
    const { vehicleConditionInfo } = useVehicleInfo();
    const { storeInfo } = useStoreInfo();
    const { conditionAnswers } = vehicleConditionInfo;

    const isAfterMarketModificationEnabled = useMemo(
        () =>
            (storeInfo.state === 'MN' || storeInfo.state === 'NJ' || storeInfo.state === 'PA') &&
            question.id === AfterMarketModificationsQuestionId &&
            conditionAnswers?.find(a => a.id === AfterMarketModificationsQuestionId)?.answers[0] === 2,
        [conditionAnswers, question.id, storeInfo.state]
    );

    if (!isAfterMarketModificationEnabled) {
        return null;
    }

    return (
        <div className={styles.afterMarketModificationAlert}>
            <AlertIcon className={styles.afterMarketModificationAlertImg} />
            <h3 className={styles.afterMarketModificationAlertHeader}>Please note</h3>
            <p className={styles.afterMarketModificationAlertText}>
                By state law, we may not be able to purchase vehicles with certain emissions system modifications,
                including:
            </p>
            <p className={classNames(styles.afterMarketModificationAlertText, styles.afterMarketModificationAlertList)}>
                • Aftermarket air intakes
            </p>

            <p className={classNames(styles.afterMarketModificationAlertText, styles.afterMarketModificationAlertList)}>
                • Missing or altered catalytic converters
            </p>
            <ConditionalRender when={storeInfo.state === 'NJ' || storeInfo.state === 'PA'}>
                <p className={styles.afterMarketModificationAlertText}>
                    If you&apos;d like to ask about your specific vehicle, call the Purchasing Department at (856) 262
                    7679 x5090 or email 7234-PM@carmax.com.
                </p>
            </ConditionalRender>
            <ConditionalRender when={storeInfo.state === 'MN'}>
                <p className={styles.afterMarketModificationAlertText}>
                    If you&apos;d like to ask about your specific vehicle, call the Purchasing Department at (763) 560
                    9329 x5090 or email 6008-SEN-Buyer@carmax.com.
                </p>
            </ConditionalRender>
        </div>
    );
};

export default AfterMarketModificationAlert;
