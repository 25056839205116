import * as React from 'react';
import { useState } from 'react';
import { IconCircle } from '@kmx/legos-react-icons';
import { useSwipeable } from 'react-swipeable';
import classNames from 'classnames';
import Slide from './Slide';

import * as styles from './styles.module.scss';

interface ISwipeable {
    children: React.ReactNode;
    childsWidth: number;
    childsHeight: number;
    startingIndex?: number;
    containerClassName?: string;
    wrapperClassName?: string;
    slideClassName?: string;
}

const SwipableContainer: React.FC<ISwipeable> = ({
    children,
    childsWidth,
    childsHeight,
    startingIndex,
    containerClassName,
    wrapperClassName,
    slideClassName,
}) => {
    const [selectedIndex, setSelectedIndex] = useState(startingIndex ?? 0);

    const handlers = useSwipeable({
        preventScrollOnSwipe: true,
        trackMouse: true,
        onSwipedLeft: () =>
            setSelectedIndex(prev => (prev === React.Children.toArray(children).length - 1 ? prev : prev + 1)),
        onSwipedRight: () => setSelectedIndex(prev => (prev === 0 ? 0 : prev - 1)),
    });

    const filteredChildren = React.Children.toArray(children).filter(Boolean);

    return (
        <div className={classNames(styles.sliderContainer, containerClassName)} {...handlers}>
            <div
                aria-live="polite"
                className={classNames(styles.sliderWrapper, wrapperClassName)}
                style={{ height: childsHeight }}
            >
                {React.Children.map(filteredChildren, (c, i) => (
                    <Slide
                        key={`slide-${i}`}
                        index={i}
                        selectedIndex={selectedIndex}
                        childsWidth={childsWidth}
                        className={slideClassName}
                    >
                        {c}
                    </Slide>
                ))}
            </div>
            <div className={styles.navItems}>
                {React.Children.map(filteredChildren, (c, i) => (
                    <div
                        role="button"
                        aria-label={`slide ${i + 1}`}
                        onClick={() => setSelectedIndex(i)}
                        className={styles.navButton}
                    >
                        <IconCircle
                            key={`navItem-${i}`}
                            className={selectedIndex === i ? styles.carouselNavDotSelected : null}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SwipableContainer;
