import * as React from 'react';
import classNames from 'classnames';
import * as styles from './Card.module.scss';

interface ICardProps {
    id?: string;
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
    selected?: boolean;
    header?: string;
    content: string;
    footer?: string;
    disabled?: boolean;
    className?: string;
    icon?: React.ReactElement;
}

// TODO: Eventually refactor this component to use the LEGOs Card when selection logic is added
const Card: React.FC<ICardProps> = props => {
    const { id, selected, header, content, footer, disabled, className, icon } = props;

    const onClick = (e: any) => {
        if (typeof adobeDataLayer != 'undefined') {
            adobeDataLayer.push({
                event: 'ctaClick',
                linkDetails: {
                    name: header || null,
                    position: id || null,
                },
            });
        }

        props.onClick?.(e);
    };

    return (
        <div
            id={id}
            className={classNames([
                className,
                styles.kmxCard,
                { [styles.selected]: selected },
                { [styles.clickable]: !disabled && onClick },
            ])}
            onClick={!disabled && onClick ? onClick : undefined}
        >
            {selected ? (
                <div className={styles.kmxCardSelectedIndicator}>
                    <span>
                        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                            <path d="M0 0h24v24H0z" fill="none" />
                            <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                        </svg>
                    </span>
                </div>
            ) : null}
            {icon && <div className={styles.svgContainer}>{icon}</div>}
            <div>
                {header && <div className={classNames(['kmx-typography--headline-2'])}>{header}</div>}
                {content && <div className="kmx-typography--body-1">{content}</div>}
                {footer && (
                    <div className={classNames(['kmx-typography--fine-print', styles.kmxCardFooter])}>{footer}</div>
                )}
            </div>
        </div>
    );
};

export default Card;
