const AVAILABLE_DRIVES = [
    { value: '2WD', label: '2 Wheel Drive', code: '2WD' },
    { value: '4WD/AWD', label: '4 Wheel Drive/All Wheel Drive', code: '4WD' },
];

const AVAILABLE_TRANSMISSIONS = [
    { value: 'Automatic', label: 'Automatic' },
    { value: 'Manual', label: 'Manual' },
];

export { AVAILABLE_DRIVES, AVAILABLE_TRANSMISSIONS };
