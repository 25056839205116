import * as React from 'react';
import { useState } from 'react';

interface FormField {
    value: string;
    setValue: (v: string) => void;
    wasTouched: boolean;
    setWasTouched: (t: boolean) => void;
}

export default function useFormField(initialValue: string): FormField {
    const [value, setValue] = useState(initialValue || '');
    const [wasTouched, setWasTouched] = useState(false);

    return React.useMemo(() => ({ value, setValue, wasTouched, setWasTouched }), [value, wasTouched]);
}
