export const formatSeperatedInteger = (value: string | number): string => {
    if (typeof value === 'number') {
        return value ? value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '';
    } else {
        return value ? value.replace(/[^0-9]*/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '';
    }
};

export const mileageToInt = (mileage: string): number => {
    return parseInt(mileage.replace(/,/g, ''));
};

export const formatVin = (input: string): string => {
    if (input == null || input == '') return input;
    else return input.toUpperCase().replace(/I/g, '1').replace(/O/g, '0').replace(/Q/g, '0');
};

type DateFormatKeys = 'mm' | 'dd' | 'yyyy' | 'm' | 'd' | 'yy';

export const formatDate = (input: Date | string, format: string): string => {
    const date = typeof input === 'string' ? new Date(input) : input;

    const map = {
        mm: (date.getMonth() + 1).toString().padStart(2, '0'),
        dd: date.getDate().toString().padStart(2, '0'),
        yyyy: date.getFullYear().toString(),
        m: (date.getMonth() + 1).toString(),
        d: date.getDate().toString(),
        yy: date.getFullYear().toString().slice(-2),
    };

    return format.replace(/mm|dd|yyyy|m|d|yy/gi, (matched: DateFormatKeys) => map[matched]);
};
