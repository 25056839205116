import * as React from 'react';
import { useState, useEffect } from 'react';
import { Button as KmxButton } from '@kmx/legos-react-button';
import { RadioButton } from '@kmx/legos-react-radio-button';
import { IVehicleStyleDetail } from '../../../types/IVehicleStyle';
import { FORM_FIELD_NAMES } from '../../../constants';
import { useVehicleInfo } from '../../../context/vehicleInfo';
import { withAnalyticsClickTracking } from '../../../utils/analytics';
const Button = withAnalyticsClickTracking(KmxButton);

import * as styles from './FeaturesBody.module.scss';

interface IVehicleStyleSelectorProps {
    readOnly: boolean;
    style: IVehicleStyleDetail;
    styleGroup: IVehicleStyleDetail[];
    setStyleGroup: (s: IVehicleStyleDetail[]) => void;
    offerRefreshStyleGroupSet: boolean;
}

const VehicleStyleSelector: React.FC<IVehicleStyleSelectorProps> = ({
    readOnly,
    style,
    styleGroup,
    setStyleGroup,
    offerRefreshStyleGroupSet,
}): JSX.Element => {
    const { featureLookupInfo } = useVehicleInfo();
    const [showRemainingStyles, setShowRemainingStyles] = useState(false);
    const [selectedStyle, setSelectedStyle] = useState(
        style ? `${style.trim.display} ${style.body.display} ${style.engineSize.display}` : null
    );
    const matches = featureLookupInfo.filter(
        (x: IVehicleStyleDetail[]) =>
            x[0].weight > 0 || (styleGroup && x[0].sku === styleGroup[0]?.sku && !showRemainingStyles)
    );
    const allRemaining = featureLookupInfo.filter((x: IVehicleStyleDetail[]) => x[0].weight == 0);

    const hideRemainingButton = (
        <div>
            <Button
                level="tertiary"
                className={styles.hideAdditionalStylesButton}
                onClick={() => setShowRemainingStyles(false)}
            >
                hide additional styles
            </Button>
        </div>
    );

    //auto-select if only a single matching style
    useEffect(() => {
        //don't re-set style group for offer refresh if it's already set.
        if (matches.length === 1 && !offerRefreshStyleGroupSet) {
            setStyleGroup(matches[0]);
            setSelectedStyle(
                `${matches[0][0].trim.display} ${matches[0][0].body.display} ${matches[0][0].engineSize.display}`
            );
        }
        if (matches.length === 0) {
            setShowRemainingStyles(true);
        }
    }, []);

    const getStyleRadioButton = (s: IVehicleStyleDetail[]) => (
        <RadioButton
            checked={selectedStyle === `${s[0].trim.display} ${s[0].body.display} ${s[0].engineSize.display}`}
            label={`${s[0].trim.display} ${s[0].body.display} ${s[0].engineSize.display}`}
            name={FORM_FIELD_NAMES.vehicleFeatures.style}
            value={s[0].sku}
            onChange={() => {
                setStyleGroup(s);
                setSelectedStyle(`${s[0].trim.display} ${s[0].body.display} ${s[0].engineSize.display}`);
            }}
            key={s[0].sku}
            disabled={readOnly}
        />
    );

    return (
        <div className="kmx-flex-wrapper">
            {matches.map(getStyleRadioButton)}
            {allRemaining.length > 0 && matches.length > 0 && !showRemainingStyles && (
                <div>
                    <Button
                        id="show-more-styles"
                        level="tertiary"
                        className={styles.showMoreButton}
                        onClick={() => setShowRemainingStyles(true)}
                    >
                        show more
                    </Button>
                </div>
            )}
            {/* {allRemaining.length > 0 && showRemainingStyles && allRemaining.length > 12 && hideRemainingButton} */}
            {(showRemainingStyles || matches.length === 0) && allRemaining.map(getStyleRadioButton)}
            {allRemaining.length > 0 && !(matches.length === 0) && showRemainingStyles && hideRemainingButton}
        </div>
    );
};

export default VehicleStyleSelector;
