import * as React from 'react';
import { FC } from 'react';
import * as ReactDOM from 'react-dom';
import classnames from 'classnames';
import { nanoid } from 'nanoid';
import FocusLock from 'react-focus-lock';
import { Button as KmxButton } from '@kmx/legos-react-button';
import { useEventListener } from '@kmx/legos-react-utilities';
import { withAnalyticsClickTracking } from '../../../../../src/utils/analytics';
const Button = withAnalyticsClickTracking(KmxButton);

interface DialogProps {
    /**
     * Function to be called with the accept action
     */
    acceptAction: () => void;

    /**
     * Text to display as the accept `<button>` label
     */
    acceptButtonLabel: string;

    /** Function to be called with the cancel action */
    cancelAction: () => void;

    /**
     * Text to display as the cancel `<button>` label
     *
     * @default Cancel
     */
    cancelButtonLabel?: string;

    /**
     * Children to render in the `<Dialog />` body (`<section>`)
     */
    children: React.ReactNode;
    /**
     * Class(es) to add to the container `<div>` element
     */
    className?: string;
    /**
     * DOM element to mount the `<Dialog />` in using a React Portal
     */
    dialogRoot?: HTMLElement;
    /**
     * Text to display in the `<header>` element
     */
    headlineText?: string;
    /**
     * Boolean attribute indicating the `<Dialog />` should use "modal" behavior
     *
     * @default false
     */
    modal?: boolean;
    /**
     * Boolean attribute indicating the `<section>` (body of the `<Dialog />`) should be scrollable
     *
     * @default false
     */
    scrollable?: boolean;
}

const DIALOG_SCROLL_LOCK_CLASS = 'mdc-dialog-scroll-lock';

export const Dialog: FC<DialogProps> = props => {
    const {
        acceptAction,
        acceptButtonLabel,
        cancelAction,
        cancelButtonLabel = 'Cancel',
        children,
        className,
        dialogRoot,
        headlineText,
        modal = false,
        scrollable = false,
    } = props;

    // setup IDs for a11y use
    const [id] = React.useState(nanoid(8));
    const headerId = `header-${id}`;
    const sectionId = `section-${id}`;

    const handleAcceptAction = React.useCallback(() => acceptAction?.(), [acceptAction]);

    const handleCancelAction = React.useCallback(() => cancelAction?.(), [cancelAction]);

    const handleModalCheck = React.useCallback(() => {
        if (modal) return;
        handleCancelAction();
    }, [modal, handleCancelAction]);

    const keydownHandler = React.useCallback(
        (event: KeyboardEvent) => {
            const { key } = event;
            if (['Escape', 'Esc'].includes(key)) {
                handleModalCheck();
            }
        },
        [handleModalCheck]
    );

    // setup ref to wire up keydown
    const surfaceRef = React.useRef<HTMLDivElement | null>(null);

    // handle escape to close dialog
    useEventListener('keydown', surfaceRef, keydownHandler);

    // setup ref to use for scroll lock
    const bodyRef = React.useRef<HTMLElement>(document.body);

    // handle scroll lock via adding/removing class
    React.useEffect(() => {
        const bodyElement = bodyRef.current;

        if (bodyElement) {
            bodyElement.classList.add(DIALOG_SCROLL_LOCK_CLASS);
        }

        return () => {
            if (bodyElement) {
                bodyElement.classList.remove(DIALOG_SCROLL_LOCK_CLASS);
            }
        };
    });

    // create element to mount Dialog as a portal using dialogRoot
    let dialogContainer: HTMLElement | null = null;

    if (dialogRoot) {
        dialogContainer = document.createElement('aside');
    }

    React.useEffect(() => {
        if (dialogRoot && dialogContainer) {
            dialogRoot.appendChild(dialogContainer);
        }

        return () => {
            if (dialogRoot && dialogContainer) {
                dialogRoot.removeChild(dialogContainer);
            }
        };
    }, [dialogRoot, dialogContainer]);

    const dialog = (
        <div
            className={classnames(className, 'kmx-dialog', 'mdc-dialog--open')}
            role="alertdialog"
            aria-labelledby={headlineText ? headerId : undefined}
            aria-describedby={sectionId}
        >
            <FocusLock noFocusGuards returnFocus>
                <div className="mdc-dialog__surface" ref={surfaceRef}>
                    {headlineText && (
                        <header id={headerId} className="mdc-dialog__header">
                            <h2 className="mdc-dialog__header__title">{headlineText}</h2>
                        </header>
                    )}
                    <section
                        id={sectionId}
                        className={classnames('mdc-dialog__body', { 'mdc-dialog__body--scrollable': scrollable })}
                        tabIndex={scrollable ? 0 : undefined}
                    >
                        {children}
                    </section>
                    <footer className="mdc-dialog__footer">
                        <Button className="mdc-dialog__footer__button--cancel" onClick={handleCancelAction}>
                            {cancelButtonLabel}
                        </Button>
                        <Button className="mdc-dialog__footer__button--accept" onClick={handleAcceptAction}>
                            {acceptButtonLabel}
                        </Button>
                    </footer>
                </div>
            </FocusLock>
            <div className="mdc-dialog__backdrop" onClick={handleModalCheck} />
        </div>
    );

    return dialogRoot && dialogContainer ? ReactDOM.createPortal(dialog, dialogContainer) : dialog;
};
