import { request } from './request';

export enum KbbCondition {
    Poor = 'Poor',
    Fair = 'Fair',
    Good = 'Good',
    VeryGood = 'VeryGood',
    Excellent = 'Excellent',
}

export interface IKbbValuation {
    priceAdvisorUrl: string;
    valuation: number;
    valuationRangeLow: number;
    valuationRangeHigh: number;
    valuationDate: string;
    valuationZipCode: string;
    year: number;
    make: string;
    model: string;
    trim: string;
    customerSelectedConditionLevel: string;
    kbbVehicleId: number;
}

interface IKbbStyle {
    vehicleId: number;
    yearId: number;
    makeName: string;
    modelName: string;
    trimName: string;
}

interface IKbbStyles {
    vinResults: IKbbStyle[];
}

export const getKbbStylesV2 = (vin: string): Promise<IKbbStyles> => {
    return request('get', 'kbb', { vin }, {}, false)
        .then(response => {
            if (response && response.data) {
                return response.data;
            } else {
                return null;
            }
        })
        .catch(() => {
            return null;
        });
};
