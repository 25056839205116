import * as React from 'react';
import { useMemo } from 'react';
import { Checkbox } from '@kmx/legos-react-checkbox';
import { IDynamicAnswer, IDynamicConditionQuestion } from '../../../types/IConditionQuestion';
import ValidatedArea from '../../ValidatedArea';
import { isSelected } from '../../../utils/validation';
import { useVehicleInfo } from '../../../context/vehicleInfo';

interface IMultiSelect {
    question: IDynamicConditionQuestion;
    readOnly: boolean;
    conditionValidationTriggered: boolean;
    toggleAnswer: (question: IDynamicConditionQuestion, answerId: number) => void;
}

const MultiSelect: React.FC<IMultiSelect> = ({ toggleAnswer, question, readOnly, conditionValidationTriggered }) => {
    const { vehicleConditionInfo } = useVehicleInfo();
    const { conditionAnswers } = vehicleConditionInfo;

    const choices = useMemo(
        () =>
            question?.answers?.map((answer: IDynamicAnswer) => (
                <Checkbox
                    key={answer.id}
                    id={`ico-yn-${question.id}-${answer.id}`}
                    checked={isSelected(question, answer, conditionAnswers)}
                    onChange={() => toggleAnswer(question, answer.id)}
                    label={answer.description}
                    value={answer.id.toString()}
                    disabled={readOnly}
                />
            )),
        [conditionAnswers, question, readOnly, toggleAnswer]
    );

    return (
        <div key={question.id}>
            <fieldset id={`question-${question.id}`} className="kmx-flex-wrapper">
                {/* Get rid of validated area - this makes all multi-select questions optional, if we want nested multi-selects required, then we'll have to add this back in  */}
                <ValidatedArea
                    overlayIcon={false}
                    invalid={
                        conditionValidationTriggered &&
                        !(conditionAnswers.find(x => x.id === question.id).answers.length > 0) &&
                        question.id === 310
                    }
                    validationMessage="Please make a selection below"
                >
                    <legend>{question.description}</legend>
                </ValidatedArea>
                <div>{choices}</div>
            </fieldset>
        </div>
    );
};

export default MultiSelect;
