// extracted by mini-css-extract-plugin
var _1 = "Card-module__clickable--aU7Z4";
var _2 = "Card-module__kmx-card--selected--UZ7IG";
var _3 = "Card-module__kmx-elevation-1--or3RE";
var _4 = "Card-module__kmx-elevation-12--U5Epr";
var _5 = "Card-module__kmx-elevation-16--l_awy";
var _6 = "Card-module__kmx-elevation-4--OhXwu";
var _7 = "Card-module__kmx-elevation-8--NvJKm";
var _8 = "Card-module__kmx-typography--body-2--NxSNH";
var _9 = "Card-module__kmx-typography--headline-2--ZyiSs";
var _a = "Card-module__kmxCard--QZhDT";
var _b = "Card-module__kmxCardFooter--cENPH";
var _c = "Card-module__kmxCardSelectedIndicator--VLxU8";
var _d = "Card-module__selected--r4PIg";
var _e = "Card-module__svgContainer--PLHBQ";
export { _1 as "clickable", _2 as "kmx-card--selected", _3 as "kmx-elevation-1", _4 as "kmx-elevation-12", _5 as "kmx-elevation-16", _6 as "kmx-elevation-4", _7 as "kmx-elevation-8", _8 as "kmx-typography--body-2", _9 as "kmx-typography--headline-2", _a as "kmxCard", _b as "kmxCardFooter", _c as "kmxCardSelectedIndicator", _d as "selected", _e as "svgContainer" }
