import { AxiosResponse } from 'axios';
import { request } from './request';
import { IStartQuote } from '../types/IStartQuote';

export const startQuoteV2 = (
    vin: string,
    licensePlate: string,
    state: string,
    ciamId: string,
    zip: string,
    extraQuestions: string[]
): Promise<IStartQuote> => {
    let upgrade = false;
    if (window.localStorage && localStorage.getItem('data_upgrade')) {
        upgrade = true;
    }

    return request(
        'post',
        'quotes/start',
        {},
        {
            vin,
            licensePlate,
            state,
            ciamId,
            zipcode: zip,
            upgrade,
            extraQuestions,
        },
        false
    ).then(response => {
        if (response) {
            return response.data as IStartQuote;
        } else {
            return null;
        }
    });
};

export const getQuoteV2 = (quoteId: string, isAsync: boolean): Promise<AxiosResponse> => {
    return request('get', `quotes/${quoteId}`, { isAsyncLookup: isAsync }) as Promise<AxiosResponse>;
};
