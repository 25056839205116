import * as React from 'react';
import classNames from 'classnames';
import * as styles from './StepBody.module.scss';

interface IStepBodyProps {
    isExpanded: boolean;
    children: React.ReactNode;
}

const StepBody: React.FC<IStepBodyProps> = props => {
    return (
        <div className={classNames('kmx-stepper--step-body', styles.stepBody, { [styles.hidden]: !props.isExpanded })}>
            {props.children}
        </div>
    );
};

export default StepBody;
