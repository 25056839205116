/* eslint react/prop-types: 0 */
import * as React from 'react';
import { ReactNode, useState, createContext, useContext } from 'react';

const ClientInfoContext = createContext(null);

interface IClientInfoProviderProps {
    children: ReactNode;
    initialClientInfo: IClientInfoContext;
}

export interface IClientInfoContext {
    inStore: {
        kiosk: boolean;
    };
}

const ClientInfoProvider: React.FC<IClientInfoProviderProps> = ({ children, initialClientInfo }) => {
    const [clientInfo, setClientInfo] = useState(Object.assign({}, initialClientInfo));
    return <ClientInfoContext.Provider value={{ clientInfo, setClientInfo }}>{children}</ClientInfoContext.Provider>;
};

export function useClientInfo() {
    return useContext(ClientInfoContext);
}

export default ClientInfoProvider;
