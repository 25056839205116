import * as React from 'react';
import classNames from 'classnames';

import * as styles from './styles.module.scss';

interface ISlide {
    index: number;
    selectedIndex: number;
    childsWidth: number;
    children: React.ReactNode;
    className?: string;
}

const Slide: React.FC<ISlide> = ({ children, index, selectedIndex, childsWidth, className }) => {
    const diff = selectedIndex - index;
    const translateX = diff * -100;

    const nextSlide = index - 1 === selectedIndex;
    const prevSlide = index + 1 === selectedIndex;

    return (
        <div
            style={{
                transform: `translateX(calc(${translateX}% + ${50}vw - ${childsWidth / 2}px))`,
                marginLeft: nextSlide ? 16 : prevSlide ? -16 : 0,
            }}
            className={classNames(styles.slide, className)}
        >
            {children}
        </div>
    );
};

export default Slide;
