/* eslint react/prop-types: 0 */
import * as React from 'react';
import { ReactNode, useState, createContext, useContext, useEffect, useMemo, useCallback } from 'react';
import { UseState } from '../types/UseStateType';
import { IVehicleInfo } from '../types/IVehicleInfo';
import { IDynamicConditionAnswer, IDynamicConditionQuestion } from '../types/IConditionQuestion';
import { IVehicleStyle, IVehicleStyleDetail } from '../types/IVehicleStyle';

const VehicleInfoContext = createContext<{
    resetVehicleInfo: () => void;
    vehicleInfo: IVehicleInfo;
    setVehicleInfo: UseState<IVehicleInfo>;
    vehicleConditionInfo: IConditionInfo;
    setVehicleConditionInfo: UseState<IConditionInfo>;
    featureInfo: IFeatureInfo;
    setFeatureInfo: UseState<IFeatureInfo>;
    featureLookupInfo: IVehicleStyleDetail[][];
    setFeatureLookupInfo: UseState<IVehicleStyleDetail[][]>;
}>(null);

interface IVehicleInfoProviderProps {
    children: ReactNode;
    InitialVehicleInfo: IVehicleInfo;
}

export const vehicleInfoInitialState: IVehicleInfo = {
    isComplete: false,
    vin: '',
    plate: null,
    state: null,
    zipcode: '',
    profile: {
        year: null,
        yearCode: null,
        make: null,
        makeCode: null,
        model: null,
        modelCode: null,
        trim: null,
        trimCode: null,
        bodyCode: null,
    },
};

export type IFeatureInfo = {
    isComplete: boolean;
    style: IVehicleStyle;
    drive: string;
    transmission: string;
    standardOptions: string[];
    availableOptions: string[];
};

const featureInfoInitialState: IFeatureInfo = {
    isComplete: false,
    style: null,
    drive: null,
    transmission: null,
    standardOptions: [],
    availableOptions: [],
};

export type IConditionInfo = {
    conditionAnswers: IDynamicConditionAnswer[];
    conditionQuestions: IDynamicConditionQuestion[];
    isComplete: boolean;
    mileage: string;
    errorCount: number;
};

const conditionInfoInitialState: IConditionInfo = {
    conditionAnswers: [],
    conditionQuestions: [],
    isComplete: false,
    mileage: '',
    errorCount: 0,
};

const initalizeConditionAnswers = (
    conditionQuestions: IDynamicConditionQuestion[],
    existingAnswers: IDynamicConditionAnswer[]
): IDynamicConditionAnswer[] => {
    const answers: IDynamicConditionAnswer[] = [];
    conditionQuestions.forEach((question: IDynamicConditionQuestion) => {
        const answer = {
            id: question.id,
            answers: [],
        } as IDynamicConditionAnswer;

        const prevAnswer = existingAnswers.find(x => x.id === answer.id);
        answers.push(prevAnswer ? prevAnswer : answer);

        question.answers.forEach(answer => {
            if (answer.detailQuestions) {
                answers.push(...initalizeConditionAnswers(answer.detailQuestions, existingAnswers));
            }
        });
    });
    return answers;
};

const VehicleInfoProvider: React.FC<IVehicleInfoProviderProps> = ({ children, InitialVehicleInfo }) => {
    const [vehicleInfo, setVehicleInfo] = useState(Object.assign({}, vehicleInfoInitialState, InitialVehicleInfo));
    const [vehicleConditionInfo, setVehicleConditionInfo] = useState<IConditionInfo>(conditionInfoInitialState);
    const [featureInfo, setFeatureInfo] = useState<IFeatureInfo>(featureInfoInitialState);
    const [featureLookupInfo, setFeatureLookupInfo] = useState<IVehicleStyleDetail[][]>([]);
    const [conditionAnswersInitialized, setConditionAnswersInitialized] = useState(false);

    useEffect(() => {
        if (!conditionAnswersInitialized && vehicleConditionInfo.conditionQuestions.length > 0) {
            initialize(vehicleConditionInfo.conditionQuestions);
            setConditionAnswersInitialized(true);
        }
    }, [conditionAnswersInitialized, vehicleConditionInfo.conditionQuestions]);

    const initialize = (conditionQuestions: IDynamicConditionQuestion[]) => {
        setVehicleConditionInfo({
            ...vehicleConditionInfo,
            conditionAnswers: initalizeConditionAnswers(conditionQuestions, vehicleConditionInfo.conditionAnswers),
        });
    };

    const resetVehicleInfo = useCallback(() => setVehicleInfo(vehicleInfoInitialState), []);

    const values = useMemo(
        () => ({
            vehicleInfo,
            vehicleConditionInfo,
            featureInfo,
            featureLookupInfo,
            setVehicleInfo,
            setVehicleConditionInfo,
            setFeatureInfo,
            setFeatureLookupInfo,
            resetVehicleInfo,
        }),
        [
            vehicleInfo,
            vehicleConditionInfo,
            featureInfo,
            featureLookupInfo,
            setVehicleInfo,
            setVehicleConditionInfo,
            setFeatureInfo,
            setFeatureLookupInfo,
            resetVehicleInfo,
        ]
    );

    return <VehicleInfoContext.Provider value={values}>{children}</VehicleInfoContext.Provider>;
};

export function useVehicleInfo() {
    return useContext(VehicleInfoContext);
}

export default VehicleInfoProvider;
