import * as React from 'react';
import { useState, useEffect, useMemo, useCallback } from 'react';

interface IAccordionStepperProps {
    hostControlledStepExpansion?: boolean;
    allCollapsed: boolean;
    children: JSX.Element | JSX.Element[];
}

const AccordionStepper: React.FC<IAccordionStepperProps> = props => {
    // Track the state of each step
    const childArr = useMemo(
        () => React.Children.toArray(props.children).filter(x => x) as JSX.Element[],
        [props.children]
    );
    const [stepDisabled, setStepDisabled] = useState<boolean[]>(childArr.map((d, i) => i != 0)); // Initially disable all steps except the first
    const [stepExpanded, setStepExpanded] = useState<boolean[]>(childArr.map((d, i) => i == 0)); // Initially expand only the first step
    const [stepLoading, setStepLoading] = useState<boolean[]>(childArr.map(() => false));
    const [stepComplete, setStepComplete] = useState<boolean[]>(childArr.map(() => false));

    useEffect(() => {
        if (props.allCollapsed) {
            // Update every step's expanded flag to be collapsed
            setStepExpanded(prevState => prevState.map(() => false));
        }
    }, [props.allCollapsed]);

    const findChildIndex = useCallback(
        (id: string) => childArr.findIndex((x: JSX.Element) => x.props.id === id),
        [childArr]
    );

    const onStepHeaderClick = (id: string) => {
        // Check to make sure the step is not disabled or loading already before trying to expand it
        const stepIndex = findChildIndex(id);
        if ((props.hostControlledStepExpansion || !stepDisabled[stepIndex]) && !stepLoading[stepIndex]) {
            expandStep(id, stepDisabled[stepIndex]);
        }
    };

    /**
     * Try to expand a step.
     * - Mark the step as loading
     * - Execute the `onAttemptStepExpand` function if one was defined for the step, otherwise create a promise that resolves immediately.
     * -- If the promise completes successfully, mark the step as no longer loading and expand the step
     * -- If the promise fails, mark the step as no longer loading and expand the previous step
     *
     * @param id The step to expand
     */
    const expandStep = (id: string, isDisabled: boolean) => {
        // Mark the step as loading
        const stepIndex = findChildIndex(id);
        const childProps = childArr[stepIndex].props;
        setStepLoading(prevState => prevState.map((prevLoading, i) => i == stepIndex));

        // Get the load function for the step if there is one
        const attemptExpandFunction =
            childProps.onAttemptStepExpand != null ? childProps.onAttemptStepExpand : () => Promise.resolve();

        // Load data for the step if a function is provided
        attemptExpandFunction(isDisabled).then(
            () => {
                // Mark the step as not loading anymore if loading was successful
                setStepLoading(prevState => prevState.map(() => false));

                // Expand the step, collapsing all others.
                setStepExpanded(prevState => prevState.map((prevExpanded, i) => i == stepIndex));

                // Execute the "step expanded" function if one was passed
                if (childProps.onStepExpanded) {
                    childProps.onStepExpanded();
                }
            },
            () => {
                // If loading failed, mark the step as not loading anymore
                setStepLoading(prevState => prevState.map(() => false));
            }
        );
    };

    // Set a step to either complete or not complete and update the next step's disabled status
    const setStepCompletion = (id: string, isComplete: boolean) => {
        const stepIndex = findChildIndex(id);
        if (stepComplete[stepIndex] != isComplete) {
            // Set this step's completion status
            setStepComplete(prevState =>
                prevState.map((prevComplete, i) => (i === stepIndex ? isComplete : prevComplete))
            );

            // Update the next step to be disabled if this step is not complete, or enabled if this step is complete.
            setStepDisabled(prevState =>
                prevState.map((prevDisabled, i) => (i == 0 ? false : i == stepIndex + 1 ? !isComplete : prevDisabled))
            );

            // Automatically expand the next step when this step completes if the autoProceedOnComplete flag is enabled for the step.
            if (childArr[stepIndex].props.autoProceedOnComplete && isComplete && stepIndex != childArr.length - 1) {
                // Collapse all steps
                setStepExpanded(prevState => prevState.map(() => false));

                // Try to expand the next step
                expandStep(childArr[stepIndex + 1].props.id, false);
            }
        }
    };

    const makeValidId = (text: string) => {
        return text.replace(/\W/g, '_');
    };

    const children = React.Children.map(childArr, (child, index) =>
        React.cloneElement(child, {
            key: child.props.id,
            setStepCompletion: (isComplete: boolean) => setStepCompletion(child.props.id, isComplete),
            StepHeaderProps: {
                id: `ico-step-${makeValidId(child.props.id)}-btn`,
                isDisabled: stepDisabled[index],
                isExpanded: stepExpanded[index],
                isLoading: stepLoading[index],
                isComplete: stepComplete[index],
                onClick: () => onStepHeaderClick(child.props.id),
            },
        })
    );

    return <>{children}</>;
};

export default AccordionStepper;
