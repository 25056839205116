import * as React from 'react';

interface IConditionalRenderProps {
    when: boolean;
    children: JSX.Element;
}

const ConditionalRender: React.FC<IConditionalRenderProps> = ({ when, children }): JSX.Element => {
    if (when) {
        return children;
    }

    return null;
};

export default ConditionalRender;
