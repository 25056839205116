import React, { useEffect, useState } from 'react';

export default function useFocus<T extends HTMLElement>(inputRef: React.MutableRefObject<T>): boolean {
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        const handleFocus = () => setIsFocused(true);
        const handleBlur = () => setIsFocused(false);

        if (inputRef.current) {
            inputRef.current.addEventListener('focus', handleFocus);
            inputRef.current.addEventListener('blur', handleBlur);
        }

        return () => {
            if (inputRef.current) {
                inputRef.current.removeEventListener('focus', handleFocus);
                inputRef.current.removeEventListener('blur', handleBlur);
            }
        };
    }, [inputRef?.current]);

    return isFocused;
}
