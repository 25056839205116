import * as React from 'react';
import * as styles from './IcoRadio.module.scss';
import classNames from 'classnames';
import { RadioButton } from '@kmx/legos-react-radio-button';
import PillRadio from '../Pill/PillRadio';

interface IIcoRadioProps {
    /**
     * Class(es) to add to the form field container
     */
    className?: string;

    /**
     * Boolean attribute indicating whether or not this radio is currently selected (checked)
     */
    selected: boolean;

    /**
     * Boolean attribute indicating if the input should be disabled
     *
     * @default false
     */
    disabled?: boolean;

    /**
     * ID used for the `<input>` and other accessibility concerns
     */
    id?: string;

    /**
     * Attributes to pass to the `<input>` element
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    inputProps?: { [prop: string]: any };

    /**
     * Caption for the radio
     */
    label: string;

    /**
     * Name of the input
     * Note: should be used to group `<IcoRadio/>`'s
     */
    name?: string;

    /**
     * Boolean attribute indicating the `<input>` should be required
     *
     * @default false
     */
    required?: boolean;

    /**
     * Value to pass to the `<input>`
     */
    value?: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const IcoRadio: React.FC<IIcoRadioProps> = props => {
    const {
        className,
        selected,
        disabled = false,
        id,
        inputProps,
        label,
        name,
        onBlur,
        onChange,
        onFocus,
        required = false,
        value,
    } = props;

    return (
        <>
            <RadioButton
                className={classNames(className, styles.legosRadio)}
                id={`ico-r-${id}`}
                name={`ico-r-${name}`}
                value={value}
                checked={selected}
                label={label}
                disabled={disabled}
                required={required}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                {...inputProps}
            />
            <PillRadio
                className={classNames(className, styles.pillRadio)}
                id={`ico-p-${id}`}
                name={`ico-p-${name}`}
                value={value}
                selected={selected}
                label={label}
                disabled={disabled}
                required={required}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                {...inputProps}
            />
        </>
    );
};

export default IcoRadio;
