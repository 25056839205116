import * as React from 'react';
import { useMemo } from 'react';
import TextField from '../../../../../packages/online-appraisals-legos/src/components/TextField';
import { formatSeperatedInteger } from '../../../../utils/format';
import { UseState } from '../../../../types/UseStateType';
import { getMileageValidationError } from '../../../../utils/validation';

import * as styles from './styles.module.scss';

interface MileageProps {
    readOnly: boolean;
    value: string;
    setValue: UseState<string>;
    wasTouched: boolean;
    setWasTouched: UseState<boolean>;
}

const MileageInput: React.FC<MileageProps> = ({ readOnly, value, wasTouched, setValue, setWasTouched }) => {
    const [cursor, setCursor] = React.useState(null);
    const inputRef = React.useRef(null);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const formatted = formatSeperatedInteger(e.target.value);

        // Comma formatting the input resets the cursor position, we need to maintain it ourselves.
        let cursorPos = e.target.selectionStart;
        if (formatted !== value) {
            const oldLength = e.target.value.length;
            const newLength = formatted.length;
            cursorPos = newLength - oldLength + e.target.selectionStart;
        }

        setValue(formatted);
        setCursor(cursorPos);
    };

    React.useEffect(() => {
        if (inputRef.current && cursor != null) {
            inputRef.current.selectionStart = cursor;
            inputRef.current.selectionEnd = cursor;
        }
    }, [value, cursor]);

    const validationError = useMemo(() => getMileageValidationError(value), [value]);

    return (
        <>
            <div className={styles.header}>
                <h3 className="kmx-typography--headline-2">Mileage</h3>
            </div>
            <fieldset>
                <TextField
                    label="Current Mileage"
                    name="currentMileage"
                    value={value}
                    onChange={handleChange}
                    disabled={readOnly}
                    onBlur={() => setWasTouched(true)}
                    validationStatus={!wasTouched ? null : validationError ? 'invalid' : 'valid'}
                    helperText={wasTouched ? validationError : null}
                    maxLength={7}
                    inputMode="numeric"
                    ref={inputRef}
                />
            </fieldset>
        </>
    );
};

export default MileageInput;
