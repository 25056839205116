import * as React from 'react';
import classNames from 'classnames';
import { IconWarning } from '@kmx/legos-react-icons';
import * as styles from './ValidatedArea.module.scss';

interface IValidatedAreaProps {
    overlayIcon: boolean;
    invalid: boolean;
    validationMessage: string;
    className?: string;
    children: React.ReactNode;
    helperTextId?: string;
}

const ValidatedArea: React.FC<IValidatedAreaProps> = props => {
    return (
        <div
            className={classNames(props.className, {
                [styles.invalid]: props.invalid,
                [styles.overlayIcon]: props.overlayIcon,
            })}
        >
            <div className={styles.top}>
                <div className={styles.questionWrapper}>{props.children}</div>
                {props.invalid && (
                    <div className={styles.validationIconOuter}>
                        <div className={styles.validationIconInner}>
                            <IconWarning className={styles.validationIcon} />
                        </div>
                    </div>
                )}
            </div>
            {props.invalid && (
                <div
                    className={classNames('mdc-text-field-helper-text', styles.validationMessage)}
                    style={{ display: props.validationMessage === 'decodefail' ? 'none' : 'unset' }}
                    id={props.helperTextId}
                >
                    {props.validationMessage}
                </div>
            )}
        </div>
    );
};

export default ValidatedArea;
