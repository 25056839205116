import * as React from 'react';
import classNames from 'classnames';
import * as styles from './AccordionStepper.module.scss';
import StepHeader, { IStepHeaderProps } from './StepHeader';
import StepBody from './StepBody';

export interface IStep {
    id: string;
    className?: string;
    StepHeaderProps?: IStepHeaderProps;
    setStepCompletion?: (isComplete: boolean) => void;
    header: JSX.Element | string;
    children: JSX.Element | JSX.Element[];
    autoProceedOnComplete: boolean;
    onAttemptStepExpand: (stepIsDisabled: boolean) => Promise<void>;
    onStepExpanded?: () => void;
}

const Step: React.FC<IStep> = ({ id, setStepCompletion, className, children, StepHeaderProps, header, ...props }) => {
    return (
        <div id={id} className={classNames('kmx-stepper--step', styles.stepContainer, className)}>
            <StepHeader {...StepHeaderProps}>{header}</StepHeader>
            <StepBody isExpanded={StepHeaderProps.isExpanded}>
                {React.Children.map(children, child =>
                    React.cloneElement(child, {
                        ...props,
                        onCompleteChange: (isComplete: boolean) => setStepCompletion(isComplete),
                    })
                )}
            </StepBody>
        </div>
    );
};

export default Step;
