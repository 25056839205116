import * as React from 'react';
import { IconHelpOutline } from '@kmx/legos-react-icons';
import ICODialog from './ICODialog';
import * as styles from './KbbConditionDialog.module.scss';
import classNames from 'classnames';

const conditionContent = [
    {
        title: 'Excellent',
        body: '“Excellent” condition means that the vehicle looks new and is in excellent mechanical condition. This vehicle has never had any paint or bodywork and does not need reconditioning. The engine compartment is clean and free of fluid leaks. This vehicle is free of rust. The body and interior are free of wear or visible defects. The tires all match and are like new. This vehicle has a clean title history and will pass a safety and smog inspection. This vehicle has complete and verifiable service records.',
    },
    {
        title: 'Very Good',
        body: '“Very Good” condition means that the vehicle has minor cosmetic defects and is in excellent mechanical condition. This vehicle has had minor or no paint or bodywork, and requires minimal reconditioning. The engine compartment is clean and free of fluid leaks. This vehicle is free of rust. The body and interior have minimal signs of wear or visible defects. The tires all match and have 75% or more of tread remaining. This vehicle has a clean title history and will pass a safety and smog inspection. Most service records are available.',
    },
    {
        title: 'Good',
        body: '“Good” condition means that the vehicle has some cosmetic repairable defects and is free of major mechanical problems. The paint and bodywork may require minor touch-ups. The engine compartment may have minor leaks. This vehicle has only minor cosmetic or no rust. The body may have minor scratches or dings and the interior has minor blemishes characteristic of normal wear. The tires match and have at least 50% of tread remaining. Though it may need some reconditioning, it has a clean title history and will pass safety and smog inspection. Some service records are available.',
    },
    {
        title: 'Fair',
        body: '“Fair” condition means that the vehicle has some cosmetic defects that require repairing and/or replacing and requires some mechanical repairs. The paint and bodywork may require refinishing and body repair. The engine compartment has leaks and may require a tune up. This vehicle may have some repairable rust damage. The body has dings, chips, or scratches and the interior has substantial wear, and may have small tears. The tires may need replacing. This vehicle needs servicing, but is still in reasonable running condition. Has a clean title history. A few service records are available.',
    },
];

export interface IKbbConditionDialogProps {
    closeKbbDialog: () => void;
}

const KbbConditionDialog: React.FC<IKbbConditionDialogProps> = ({ closeKbbDialog }) => (
    <ICODialog closeDialog={closeKbbDialog}>
        <div className={styles.kbbConditionDialogContent}>
            <IconHelpOutline className={styles.helpIcon} height="67" width="67" />
            <h4 className={classNames('.kmx-typography--headline-3', styles.kbbHeader)}>
                Kelley Blue Book<sup>&reg;</sup> Condition Definitions
            </h4>
            {
                <div className={styles.kbbBody}>
                    {conditionContent.map(c => {
                        return (
                            <div key={c.title}>
                                <h4>{c.title}</h4>
                                <p className="kmx-typography--body-2">{c.body}</p>
                            </div>
                        );
                    })}
                </div>
            }
        </div>
    </ICODialog>
);

export default KbbConditionDialog;
