import { IKbbValuation } from '../api/kbb';
import { IEligibleOffer, IIneligibleOffer, IMultipleOfferStorage } from '../types/SavedResult';
import Cookies from 'js-cookie';

export const eligibleInstantOfferLocalStorageName = 'suycIcoOffer';
export const ineligibleInstantOfferLocalStorageName = 'suycIcoOfferIneligible';
export const eligibleType = 'eligible';
export const ineligibleType = 'ineligible';

export const multipleInstantOfferLocalStorageName = 'suycMultipleIcoOffer';

export const saveResultToLocalStorage = (type: string, result: IEligibleOffer | IIneligibleOffer): void => {
    resetSavedResult();
    window.localStorage.setItem(
        type === eligibleType ? eligibleInstantOfferLocalStorageName : ineligibleInstantOfferLocalStorageName,
        JSON.stringify(result)
    );
    storeAdditionalCarOffer(result);
    // Cookies.set(type === eligibleType ? eligibleInstantOfferLocalStorageName : ineligibleInstantOfferLocalStorageName, JSON.stringify(result));
};

export const getResultFromLocalStorage = (type: string): IEligibleOffer | IIneligibleOffer => {
    try {
        // let offerJson = Cookies.get(type === eligibleType ? eligibleInstantOfferLocalStorageName : ineligibleInstantOfferLocalStorageName);
        let offerJson = null;
        if (!offerJson) {
            offerJson = window.localStorage.getItem(
                type === eligibleType ? eligibleInstantOfferLocalStorageName : ineligibleInstantOfferLocalStorageName
            );
        }
        return offerJson ? JSON.parse(offerJson) : null;
    } catch (ex) {
        console.error('Error retrieving saved offer', ex);
    }
    return null;
};

export const resetSavedResult = (): void => {
    try {
        window.localStorage.removeItem(eligibleInstantOfferLocalStorageName);
        window.localStorage.removeItem(ineligibleInstantOfferLocalStorageName);
        Cookies.remove(eligibleInstantOfferLocalStorageName);
        Cookies.remove(ineligibleInstantOfferLocalStorageName);
    } catch (err) {
        console.error('error resetting ICO state', err);
    }
};

//Disable for non-KMX hosts in prod
export const checkHost = (): boolean => {
    if (process.env.ENV === 'production' && window.location.host === 'www.carmax.com') {
        return true;
    }
    if (process.env.ENV !== 'production') {
        return true;
    }
    return false;
};

export const storeAdditionalCarOffer = (offer: IEligibleOffer | IIneligibleOffer): void => {
    let offerVin: string = null;

    // Clone the offer.
    const offerToSave = { ...offer };
    if ('vehicleInformation' in offerToSave) {
        offerVin = offerToSave.vehicleInformation.vin;

        // Requirement to remove KBB data expect priceAdvisorUrl.
        if (offerToSave.kbbValues) {
            // So that when we change the offer data, it doesn't affect the one used.
            const kbbValues: IKbbValuation = { ...offerToSave.kbbValues };

            Object.keys(kbbValues).forEach(key => {
                if (['priceAdvisorUrl', 'customerSelectedConditionLevel'].indexOf(key) != -1) {
                    return;
                }

                delete (kbbValues as any)[key];
            });
            offerToSave.kbbValues = kbbValues;
        }
    } else {
        offerVin = offerToSave.vin;
    }

    if (offerVin == null) {
        // Technically an error state.
        return;
    }

    const garageString = window.localStorage.getItem(multipleInstantOfferLocalStorageName);

    let garage: IMultipleOfferStorage;
    if (garageString != null) {
        garage = JSON.parse(garageString) as IMultipleOfferStorage;
    } else {
        // initialize the object
        garage = {
            offers: {},
            lastOfferVin: '',
        };
    }

    // Future proofing with array.
    garage.offers[offerVin] = [offerToSave];
    garage.lastOfferVin = offerVin;

    window.localStorage.setItem(multipleInstantOfferLocalStorageName, JSON.stringify(garage));
};
