import * as React from 'react';
import classNames from 'classnames';
import { IconOpenNewWindow } from '@kmx/legos-react-icons';
import * as containerStyles from './AccordionStepper.module.scss';
import * as headerStyles from './StepHeader.module.scss';
import { withAnalyticsClickTracking } from '../../utils/analytics';
const Button = withAnalyticsClickTracking('button');

export interface IEditVehicleStep {
    onClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
}

const EditVehicleStep: React.FC<IEditVehicleStep> = props => {
    return (
        <div id="edit-vehicle-step" className={classNames('kmx-stepper--step', containerStyles.stepContainer)}>
            <Button
                id="edit-vehicle-details"
                onClick={props.onClick}
                className={classNames(
                    'kmx-stepper--step-header',
                    headerStyles.stepHeader,
                    'kmx-typography--headline-2'
                )}
            >
                <span>Edit car details</span>
                <span className={headerStyles.completionIcon}>
                    <IconOpenNewWindow />
                </span>
            </Button>
        </div>
    );
};

export default EditVehicleStep;
