/* eslint react/prop-types: 0 */
import * as React from 'react';
import { ReactNode, useState, createContext, useContext, useMemo } from 'react';
import { IStoreInfo } from '../types/IStore';
import { UseState } from '../types/UseStateType';

export interface IStoreInfoContext {
    storeInfo: IStoreInfo;
    setStoreInfo: UseState<IStoreInfo>;
}

interface IStoreInfoProviderProps {
    children: ReactNode;
}

const StoreInfoContext = createContext<IStoreInfoContext>(null);

const StoreInfoProvider: React.FC<IStoreInfoProviderProps> = ({ children }) => {
    const [storeInfo, setStoreInfo] = useState<IStoreInfo>(null);

    const values = useMemo(() => ({ setStoreInfo, storeInfo }), [storeInfo]);

    return <StoreInfoContext.Provider value={values}>{children}</StoreInfoContext.Provider>;
};

export function useStoreInfo() {
    return useContext(StoreInfoContext);
}

export default StoreInfoProvider;
