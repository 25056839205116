import './polyfill';
import * as React from 'react';
import { useState, useCallback } from 'react';
import './index.scss';
import * as styles from './App.module.scss';
import { IVehicleInfo } from './types/IVehicleInfo';
import InstantCashOffer from './components/InstantCashOffer';
import VehicleInfoProvider from './context/vehicleInfo';
import CustomerInfoProvider from './context/customerInfo';
import ClientInfoProvider, { IClientInfoContext } from './context/clientInfo';
import OfferContextProvider from './context/offerContext';
import FormProvider from './context/formContext';
import FeaturesProvider from './context/features';
import StoreInfoProvider from './context/storeInfo';
import classNames from 'classnames';
import { IVinEligibility } from './types/IVinEligibility';
import { getOriginPage } from './utils/analytics';
import { IVinMatchingStylesEligibility } from './types/IVinMatchingStyles';

import { AppInsightsContext, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { portalRoot } from './components/Portal/Portal';

const EXT_DOMAINS_TO_IGNORE = ['fullstory.com', 'qualtrics.com', 'mpulse.net', 'google-analytics.com'];

export interface IAppProps {
    vehicleInfo: IVehicleInfo;
    offerId?: string;
    authRefresh: () => Promise<string>;
    hidden: boolean;
    originPage?: string;
    startingMethod?: string;
    handleIneligible: boolean;
    startQuote?: IVinEligibility;
    saveResult: boolean;
    enabledFeatures: string[];
    storeId?: string;
    vehicleStyles?: IVinMatchingStylesEligibility;
    clientInfo?: IClientInfoContext;
    extraQuestions?: string[];
    clientFeatures: string[];
    clientFeaturesEventId?: string;
}

const App: React.FC<IAppProps> = ({
    handleIneligible,
    vehicleInfo,
    offerId,
    originPage,
    enabledFeatures,
    startingMethod,
    saveResult,
    storeId,
    startQuote,
    vehicleStyles,
    hidden,
    clientInfo,
    extraQuestions,
    clientFeatures,
    clientFeaturesEventId,
}) => {
    const [containerKey, setContainerKey] = useState(1); //everytime ICO is reset, this number increments

    //changing the container div's key will throw everything away and start over, resetting all of the inital state for all elements.
    const reset = useCallback(() => {
        setContainerKey(prev => prev + 1);
        window.dispatchEvent(new CustomEvent('icoReset', { detail: 'ICO Reset' }));
    }, []);

    const useInitialProps = containerKey === 1;

    const reactPlugin = new ReactPlugin();
    const appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: `${process.env.INSTRUMENTATION_KEY}`,
            extensions: [reactPlugin],
        },
    });
    appInsights.loadAppInsights();
    appInsights.addDependencyInitializer((details: any) => {
        if (EXT_DOMAINS_TO_IGNORE.some(e => details.item.target.includes(e)) && details.item.success === true) {
            return false;
        }
        return true;
    });

    return (
        <div
            key={containerKey}
            id="ico-container"
            className={classNames('kmx-ico-component-root', styles.ico, { [styles.icoHidden]: hidden })}
        >
            <AppInsightsContext.Provider value={reactPlugin}>
                <FeaturesProvider
                    clientFeatures={clientFeatures}
                    clientFeaturesEventId={clientFeaturesEventId}
                    enabledFeatures={enabledFeatures}
                >
                    <VehicleInfoProvider
                        InitialVehicleInfo={useInitialProps ? { ...vehicleInfo, isComplete: false } : undefined}
                    >
                        <CustomerInfoProvider>
                            <ClientInfoProvider initialClientInfo={clientInfo}>
                                <StoreInfoProvider>
                                    <OfferContextProvider
                                        handleIneligible={handleIneligible}
                                        saveResult={saveResult}
                                        extraQuestions={extraQuestions}
                                    >
                                        <FormProvider startingMethod={startingMethod} originPage={originPage}>
                                            <InstantCashOffer
                                                offerId={useInitialProps ? offerId : undefined}
                                                originPage={getOriginPage(originPage)}
                                                reset={reset}
                                                enabledFeatures={enabledFeatures}
                                                storeId={storeId}
                                                startQuote={useInitialProps ? startQuote : undefined}
                                                vehicleStyles={useInitialProps ? vehicleStyles : undefined}
                                            />
                                        </FormProvider>
                                    </OfferContextProvider>
                                </StoreInfoProvider>
                            </ClientInfoProvider>
                        </CustomerInfoProvider>
                    </VehicleInfoProvider>
                </FeaturesProvider>
            </AppInsightsContext.Provider>
            <div id={portalRoot}></div>
        </div>
    );
};

export default App;
