import * as React from 'react';
import { FC, ReactNode } from 'react';
import classNames from 'classnames';

interface ButtonProps {
    /**
     * Children to render inside `<Button />`
     */
    children: ReactNode;

    /**
     * Class(es) to add to the `<button>`
     */
    className?: string;

    /**
     * Boolean attribute indicates that the user cannot interact with the `<button>`
     *
     * @default false
     */
    disabled?: boolean;

    /**
     * Ref to be added to the `<button>`
     */
    forwardedRef?: React.MutableRefObject<HTMLButtonElement | null>;

    /**
     * `<Icon />` to render inside the `<Button />`
     */
    icon?: React.FC<React.SVGAttributes<SVGElement>>;

    /**
     * Render as an icon only (no visible label)
     * Note: "tertiary" button only
     *
     * @default false
     */
    iconOnly?: boolean;

    /**
     * Id to assign to the `<button>`
     */
    id?: string;

    /**
     * Level to display, either "primary", "secondary", "tertiary"
     *
     * @default tertiary
     */
    level?: 'primary' | 'secondary' | 'tertiary';

    /**
     * Button type to render, either "button", "reset", or "submit"
     *
     * @default button
     */
    type?: 'button' | 'reset' | 'submit';

    onClick?: (event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) => void;
    onBlur?: (event: React.FocusEvent<HTMLButtonElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLButtonElement>) => void;
}

export const Button: FC<ButtonProps> = props => {
    const {
        children,
        className,
        disabled = false,
        forwardedRef,
        icon: Icon,
        iconOnly = false,
        id,
        level = 'tertiary',
        onBlur,
        onClick,
        onFocus,
        type = 'button',
    } = props;

    if (iconOnly && level !== 'tertiary') {
        throw new Error('Icon only anchor buttons must be tertiary.');
    }

    return (
        <button
            className={classNames(className, 'kmx-button', `kmx-button--${level}`)}
            disabled={disabled}
            onBlur={onBlur}
            onClick={onClick}
            onFocus={onFocus}
            id={id}
            type={type}
            ref={forwardedRef}
        >
            {Icon ? (
                <span className="svg-icon-wrapper">
                    <span className="svg-icon">
                        <Icon />
                    </span>
                    {iconOnly && level === 'tertiary' ? <span className="visually-hidden">{children}</span> : children}
                </span>
            ) : (
                children
            )}
        </button>
    );
};
