import { useMemo, useCallback, useState, useEffect, useRef } from 'react';

type T = any;
interface IRetryInterface {
    numberOfRetries?: number;
    retryDelay?: number | ((i: number) => number);
    asyncFn: () => Promise<T>;
}

/**
 * This hooks allows for an async function to retry a request multiple times
 * backing off a little each time. Or someone can pass in their own retryDelay
 * function to determine how long the async function should wait before executing.
 * @returns { data, isFetching, retry }
 */
export default function useRetryAsyncRequest() {
    const [data, setData] = useState(null);
    const [isFetching, setIsFetching] = useState(false);
    const requestTimer = useRef(null);

    useEffect(() => {
        return () => {
            clearTimeout(requestTimer.current);
        };
    }, []);

    const retry = useCallback(async ({ numberOfRetries = 3, retryDelay = 1500, asyncFn }: IRetryInterface) => {
        let response = null;

        try {
            setIsFetching(true);
            response = await asyncFn();

            // Trying multiple times to get an offer response. Backing off a little each time as well.
            for (let i = 0; i <= numberOfRetries; i++) {
                if (response == null || response?.status < 200 || response?.status > 299) {
                    await new Promise(res => {
                        requestTimer.current = setTimeout(
                            async () => {
                                response = await asyncFn();
                                res(response);
                            },
                            typeof retryDelay === 'function' ? retryDelay(i) : retryDelay * i
                        );
                    });
                }
            }

            setData(response);
        } finally {
            setIsFetching(false);
        }

        return response;
    }, []);

    return useMemo(() => ({ retry, data, isFetching }), [data, isFetching, retry]);
}
