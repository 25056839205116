// Scroll to the top of an element, smoothly if supported by the browser.
// "target" can be either an element object or a string query selector.
// Smooth scroll is the default, with an option to disable using the second optional param
// Usage:
//    scrollToElement('#AppointmentForm', true);
//    scrollToElement(document.getElementById('AppointmentForm'), false);
//    scrollToElement(document.querySelector('#AppointmentForm'));
//    scrollToElement(document.querySelector('input.invalid'));
export const scrollToElement = (target: string | Element, smoothScroll = true): void => {
    if (target != null) {
        let element;

        if (typeof target === 'string') {
            // get target element by string query selector
            element = document.querySelector(target);
        } else if (typeof target.getBoundingClientRect === 'function') {
            element = target;
        }

        // Scroll to the element
        if (element != null) {
            element.scrollIntoView({ behavior: smoothScroll ? 'smooth' : 'auto' });
        }
    }
};

// Calling focus not in timeout doesn't focus the input since upgrading react.
// It worked in react 16 but not 18. In react 17 they did make changes to how focus was handled.
// https://reactjs.org/blog/2020/08/10/react-v17-rc.html#aligning-with-browsers
// Not sure if it's related to those changes or some other of react changes since we went from 16 to 18.
export const focusElement = (target: string, timeout = 100) => {
    const element = document.querySelector(target);
    if (element) {
        setTimeout(() => (element as HTMLInputElement).focus(), timeout);
    }
};
