const FORM_FIELD_NAMES = {
    vehicleFeatures: {
        style: 'style',
        drive: 'drive',
        transmission: 'transmission',
    },
    conditionsBody: {
        mileage: 'currentMileage',
        email: 'preferredEmail',
    },
};

const ORIGIN_PAGES = {
    inStore: 'in-store',
};

const CLIENT_INELIGIBLE_ERRORS = {
    errorDecodingVin: 'error: decoding vin',
    errorDecodingPlate: 'error: decoding plate',
    errorNoStylesFound: 'error: no matching styles found',
    errorCallingQuoteRequest: 'error: network error calling /quote/request',
    errorClientSide: 'error: client error',
    errorInitialEligibility: 'error: initial eligibility',
    errorZipcodeIneligible: 'error: zipcode ineligible',
    errorZipcodeInvalid: 'error: zipcode invalid',
};

const PhotoCaptureStoreAttribute = 'InstantOfferCustomerPhotoCapture';

const EXPRESS_DROPOFF_ATTRIBUTE = 'AppraisalCheckout';

const HOME_PICKUP_ATTRIBUTE = 'AppraisalPickup';

export {
    FORM_FIELD_NAMES,
    ORIGIN_PAGES,
    CLIENT_INELIGIBLE_ERRORS,
    PhotoCaptureStoreAttribute,
    EXPRESS_DROPOFF_ATTRIBUTE,
    HOME_PICKUP_ATTRIBUTE,
};
