import './polyfill';
import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import App, { IAppProps } from './App';
import { IVehicleInfo } from './types/IVehicleInfo';
import { IAppConfig } from './types/IAppConfig';
import { vinDecode, plateDecode, IDecodeResp } from './api/decoder';
import { IStartQuote } from './types/IStartQuote';
import { IEligibleOffer, IIneligibleOffer } from './types/SavedResult';
import { getResultFromLocalStorage, resetSavedResult, checkHost, saveResultToLocalStorage } from './utils/localStorage';
import { isValidVin } from './utils/validation';
import { IVinMatchingStyles } from './types/IVinMatchingStyles';
import { initializeAppConfig } from './utils/appConfig';
import { IClientInfoContext } from './context/clientInfo';

interface IcoConfiguration {
    vehicleInformation: IVehicleInfo;
    offerId?: string;
    containerId: string;
    originPage?: string;
    startingMethod?: string;
    authRefreshHandler: () => Promise<string>;
    handleIneligible?: boolean;
    enabledFeatures?: string[];
    saveResult?: boolean;
    storeId?: string;
    appConfig?: IAppConfig;
    clientInfo?: IClientInfoContext;
    extraQuestions?: string[];
    clientFeatures?: string[];
    clientFeaturesEventId?: string;
}

export default class InstantCashOffers {
    // track quoteIds by VIN from external calls to getEligibility
    private static vinToQuoteIdCache = new Map<string, IStartQuote>();
    private static vehicleDecodeCache = new Map<string, IDecodeResp>();
    private static vehicleStylesCache = new Map<string, IVinMatchingStyles>();
    private static root: ReactDOM.Root = null;
    private static app: React.ReactElement = null;
    private static config: IcoConfiguration = null;

    // Mounts and also updates ICO
    static initialize(config: IcoConfiguration): void {
        process.env['AUTH_REFRESH_HANDLER'] = config.authRefreshHandler;
        initializeAppConfig(config.appConfig);

        // Checkout IO v2 fix, ignore previously decoded vehicle profile
        delete config.vehicleInformation?.profile;

        this.config = config;
        this.root = ReactDOM.createRoot(document.getElementById(config.containerId));
        this.app = <App {...this.getMapConfigToProps(this.config)} />;

        this.root.render(this.app);
    }

    // Unmounts ICO
    // TODO: I don't understand why they the containerId is needed to be passed in? Shouldn't that already been know.
    // Need to check to see if anyone is using this method.
    static cancel(containerId: string): void {
        const elem = document.getElementById(containerId);
        if (this.root && elem) {
            this.root.unmount();
        }
    }

    // Hides ICO, preserves state
    static hide(config: IcoConfiguration): void {
        const elem = document.getElementById(config.containerId);
        if (!elem) {
            this.root = ReactDOM.createRoot(document.getElementById(config.containerId));
        }

        this.root.render(
            <App
                vehicleInfo={config.vehicleInformation}
                authRefresh={config.authRefreshHandler}
                hidden={true}
                handleIneligible={config.handleIneligible ? true : false}
                saveResult={checkHost() && config.saveResult === false ? false : true} // default to true
                enabledFeatures={config.enabledFeatures || []}
                extraQuestions={config.extraQuestions || null}
                clientFeatures={config.clientFeatures || []}
            />
        );
    }

    static decodeVin(vin: string, authRefreshHandler: () => Promise<string>): Promise<IDecodeResp> {
        if (authRefreshHandler) process.env['AUTH_REFRESH_HANDLER'] = authRefreshHandler;
        return new Promise((res, rej) => {
            vinDecode(vin)
                .then(response => {
                    this.vehicleDecodeCache.set(vin, response);
                    res(response);
                })
                .catch(err => rej(err));
        });
    }

    static decodePlate(
        licensePlate: string,
        state: string,
        authRefreshHandler: () => Promise<string>
    ): Promise<IDecodeResp> {
        if (authRefreshHandler) process.env['AUTH_REFRESH_HANDLER'] = authRefreshHandler;
        return new Promise((res, rej) => {
            plateDecode(licensePlate, state)
                .then(response => {
                    if (response && response.vin) {
                        this.vehicleDecodeCache.set(response.vin, response);
                    }
                    res(response);
                })
                .catch(err => rej(err));
        });
    }

    static getEligibility(): Promise<boolean> {
        return Promise.resolve(true);
    }

    static initializeFeatureTests(authRefreshHandler: () => Promise<string>): void {
        process.env['AUTH_REFRESH_HANDLER'] = authRefreshHandler;
    }

    static saveResultToLocalStorage(type: string, data: IEligibleOffer | IIneligibleOffer): void {
        saveResultToLocalStorage(type, data);
    }

    static getSavedResultFromLocalStorage(type: string): IEligibleOffer | IIneligibleOffer {
        return getResultFromLocalStorage(type);
    }

    static resetSavedIcoResult(): void {
        resetSavedResult();
    }

    static validateVin(vin: string): boolean {
        return isValidVin(vin);
    }

    private static getMapConfigToProps(config: IcoConfiguration, hidden = false): IAppProps {
        const vin = config.vehicleInformation?.vin;
        return {
            vehicleInfo: config.vehicleInformation,
            offerId: config.offerId,
            authRefresh: config.authRefreshHandler,
            hidden,
            originPage: config.originPage,
            startingMethod: config.startingMethod,
            handleIneligible: config.handleIneligible ? true : false,
            saveResult: checkHost() && config.saveResult === false ? false : true, // default to true
            startQuote: vin == null ? null : { [vin]: this.vinToQuoteIdCache.get(vin) },
            vehicleStyles: vin == null ? null : { [vin]: this.vehicleStylesCache.get(vin) },
            enabledFeatures: config.enabledFeatures || [],
            storeId: config.storeId,
            clientInfo: config.clientInfo,
            extraQuestions: config.extraQuestions || null,
            clientFeatures: config.clientFeatures || [],
            clientFeaturesEventId: config.clientFeaturesEventId || '',
        };
    }
}
