import * as React from 'react';
import ReactDOM from 'react-dom';

interface PortalProps {
    children: React.ReactNode;
}

export const portalRoot = 'portal-root';

const Portal: React.FC<PortalProps> = ({ children }) => {
    return ReactDOM.createPortal(children, document.getElementById(portalRoot));
};

export default Portal;
