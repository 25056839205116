import * as React from 'react';
import OfferRefreshDialog from '../OfferRefreshDialog';
import { IQuote } from '../../types/IQuote';

interface IOfferRefreshProps {
    reset: () => void;
    previousOffer: IQuote;
    setRefreshOfferchanges: (refreshOfferchanges: boolean) => void;
}

const OfferRefresh: React.FC<IOfferRefreshProps> = props => (
    <>
        {props.previousOffer && (
            <OfferRefreshDialog closeDialog={props.reset} setOtherChanges={props.setRefreshOfferchanges} />
        )}
    </>
);

export default OfferRefresh;
