export const getCookieValue = (cookieName: string, propertyName: string): string => {
    return (
        document.cookie
            .split('; ')
            .find(row => row.startsWith(cookieName))
            ?.split('=')
            .slice(1)
            .join('=')
            .split('&')
            .find(x => x.startsWith(propertyName))
            ?.split('=')[1] || null
    );
};
