import * as React from 'react';
import * as styles from './KbbValuation.module.scss';
import { formatDate } from '../../utils/format';
import { useOfferContext } from '../../context/offerContext';
import { testNames, useFeatures } from '../../context/features';
import { Select } from '@kmx/legos-react-select';
import classNames from 'classnames';

const KBB_CONDITIONS = ['Fair', 'Good', 'Very Good', 'Excellent'];
const HEADER = <h3 className={styles.compareToKbb}>Compare our offer</h3>;

const KbbValuation: React.FC = () => {
    const { offer, kbb } = useOfferContext();
    const { isFeatureEnabled } = useFeatures();
    const [iframeSrc, setIframeSrc] = React.useState(kbb.priceAdvisorUrl);
    const [condition, setCondition] = React.useState(kbb.customerSelectedConditionLevel);
    const kbbOfferPesentationTestEnabled = isFeatureEnabled(testNames.KBB_OFFER_PRESENTATION_B);

    React.useEffect(() => {
        const url = new URL(iframeSrc);
        // Strip spaces from condition (i.e. Very Good = VeryGood)
        const conditionParam = condition.replace(/\s/g, '');
        url.searchParams.set('Condition', conditionParam);
        setIframeSrc(url.toString());
    }, [condition, iframeSrc]);

    return (
        <div
            className={classNames(styles.kbbValuationContainer, {
                [styles.kbbOfferPesentationTest]: kbbOfferPesentationTestEnabled,
            })}
        >
            {!kbbOfferPesentationTestEnabled && HEADER}
            <div className={styles.kbbWidgetAndDate}>
                {kbbOfferPesentationTestEnabled && HEADER}
                <iframe
                    src={iframeSrc}
                    className={styles.kbbWidgetFrame}
                    width={300}
                    height={385}
                    aria-label="KBB Valuation"
                />
                <div className={styles.kbbGenerationDate}>
                    Generated on {formatDate(offer.createdDateUtc, 'mm/dd/yyyy')}
                </div>
                {kbbOfferPesentationTestEnabled && (
                    <Select
                        label="Condition"
                        options={KBB_CONDITIONS.map(c => ({
                            value: c,
                            label: c,
                        }))}
                        onChange={e => setCondition(e.target.value)}
                        value={condition}
                        className={styles.kbbConditionSelector}
                    />
                )}
            </div>
        </div>
    );
};

export default KbbValuation;
