import * as React from 'react';
import * as styles from '../ConditionsBody/ConditionsBody.module.scss';
import { IDynamicConditionQuestion, IDynamicConditionAnswer } from '../../../types/IConditionQuestion';
import MultiSelect from './MultiSelect';
import SingleSelect from './SingleSelect';
import { useVehicleInfo } from '../../../context/vehicleInfo';

interface IConditionsQuestionsDynamicProps {
    allValidationTriggered: boolean;
    readonly: boolean;
    updatedAnswers: (answers: IDynamicConditionAnswer[]) => void;
}

const ConditionQuestionsDynamic: React.FC<IConditionsQuestionsDynamicProps> = props => {
    const { vehicleConditionInfo } = useVehicleInfo();

    const renderQuestions = (questions: IDynamicConditionQuestion[]) => {
        if (!questions) {
            return null;
        }
        return questions
            .sort((x, y) => (x.displayOrder >= y.displayOrder ? 1 : -1))
            .map(question => {
                if (question.answerType === 'single') {
                    return (
                        <SingleSelect
                            conditionValidationTriggered={props.allValidationTriggered}
                            toggleAnswer={toggleAnswer}
                            question={question}
                            readOnly={props.readonly}
                            renderQuestions={renderQuestions}
                            key={question.id}
                        />
                    );
                } else if (question.answerType === 'multiple') {
                    return (
                        <MultiSelect
                            question={question}
                            readOnly={props.readonly}
                            conditionValidationTriggered={props.allValidationTriggered}
                            toggleAnswer={toggleAnswer}
                            key={question.id}
                        />
                    );
                } else {
                    return null;
                }
            });
    };

    const toggleAnswer = (question: IDynamicConditionQuestion, answerId: number) => {
        const questionIndex = vehicleConditionInfo.conditionAnswers.findIndex(q => q.id === question.id);
        const answers = vehicleConditionInfo.conditionAnswers[questionIndex].answers;
        let selections = null;
        //single select
        if (question.answerType === 'single') {
            selections = [answerId];
        }
        //multi select
        else if (answers.filter(answerDetails => answerDetails === answerId).length > 0) {
            //de-select the answer
            selections = answers.filter(a => a !== answerId);
        } else {
            //answer not selected yet
            selections = [...answers, answerId];
        }

        const newConditionAnswers = [
            ...vehicleConditionInfo.conditionAnswers.slice(0, questionIndex),
            { ...vehicleConditionInfo.conditionAnswers[questionIndex], answers: selections },
            ...vehicleConditionInfo.conditionAnswers.slice(questionIndex + 1),
        ] as IDynamicConditionAnswer[];
        props.updatedAnswers(newConditionAnswers);
    };

    return (
        <div id="DynamicConditionQuestions" className={styles.container}>
            {renderQuestions(vehicleConditionInfo.conditionQuestions)}
        </div>
    );
};

export default ConditionQuestionsDynamic;
