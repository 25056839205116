import { useState, useEffect, useMemo } from 'react';
import { UseState } from '../types/UseStateType';

export default function useFormFeaturesState(initialState: string | null): [string, UseState<string>] {
    const [state, setState] = useState<string>(initialState ?? '');

    // Update the state if it changes in the featureInfo, most likely because something came back from a VIN decode or was reset from manual vehicle entry
    useEffect(() => {
        setState(initialState ?? '');
    }, [initialState]);

    return useMemo(() => [state, setState], [state]);
}
